import { FC, useState } from 'react';
import { Box, Button, Modal, Paper, Typography } from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import UserPlusIcon from '@/components/icons/UserPlus';
import InviteMemberForm from '@/components/inviteMemberForm';
import { useAlert } from '@/contexts/AlertContext';
import useSession from '@/hooks/useSession';
import { WORKSPACE_ROLES } from '@/const/workspace';
import { usePostInvitationMutation } from '@/services/api/invitations';
import { usePermissions } from '@/utils/userPermissions';

const AddMemberModal: FC = () => {
  const { setAlert } = useAlert();
  const { currentWorkspaceId } = useSession();
  const [open, setOpen] = useState(false);
  const [onInvite] = usePostInvitationMutation();
  const { getPermissionsForAddMembers } = usePermissions();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSubmit = async (values: { email: string }) => {
    setOpen(false);

    if (!currentWorkspaceId) {
      setAlert({ type: 'error', description: 'You are not in a workspace.' });
      return;
    }

    onInvite({
      email: values.email,
      workspaceId: currentWorkspaceId,
      role: WORKSPACE_ROLES.ADMIN,
      expirationPeriod: 14
    }).then((response: any) => {
      if (response?.error) {
        setAlert({ type: 'error', description: response.error.data.message });
      } else {
        setAlert({ type: 'success', description: 'The invite was sent.' });
      }
    });
  };

  return (
    <Box>
      <Button
        startIcon={<AddOutlinedIcon fontSize="small" />}
        variant="contained"
        color="primary"
        onClick={handleOpen}
        disabled={!getPermissionsForAddMembers()}
        sx={{
          padding: '0.45rem 1rem',
          textTransform: 'none',
          borderRadius: '12px',
          backgroundColor: '#0078C8',
          '&:hover': { backgroundColor: '#0B789B' }
        }}
      >
        Add members
      </Button>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            borderRadius: '12px'
          }}
        >
          <Paper elevation={12} sx={{ width: '500px', p: 3 }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Box
                sx={{
                  color: '#0078C8',
                  textAlign: 'center'
                }}
              >
                <UserPlusIcon fontSize="small" width="60px" height="60px" />
              </Box>
              <Box sx={{ textAlign: 'center' }}>
                <Typography variant="h5">Add members</Typography>
                <Typography
                  color="textSecondary"
                  sx={{ mt: 1, width: '270px', fontSize: '16px' }}
                  variant="body2"
                >
                  Enter the email address of the person you want to invite
                </Typography>
              </Box>
            </Box>
            <InviteMemberForm onSubmit={handleSubmit} />
          </Paper>
        </Box>
      </Modal>
    </Box>
  );
};

export default AddMemberModal;
