import { OutlinedLightButton } from '@/components';
import { AuthLayout } from '@/components/auth/Layout';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export const VerifyEmailPage = () => {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>Your email is not verified</title>
      </Helmet>
      <AuthLayout>
        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '15px',
              fontSize: '20px'
            }}
          >
            <div>Your email is not yet verified.</div>
            <div>
              {' '}
              Please{' '}
              <Link
                style={{
                  color: 'blue',
                  textDecoration: 'underline'
                }}
                to="https://accounts.intuit.com/app/account-manager/security"
              >
                Login to your intuit account
              </Link>{' '}
            </div>
            <OutlinedLightButton
              fullWidth
              label="Back to sign in"
              onClick={() => navigate('/signin')}
              sx={{
                color: '#747A82'
              }}
              startIcon={
                <ArrowBackIcon
                  sx={{
                    color: '#747A82'
                  }}
                />
              }
            />
          </div>
        </div>
      </AuthLayout>
    </>
  );
};
