import { useGetCompaniesQuery } from '@/services';
import { TGetPageParams } from '@/services/api/reports/types';
import { getPagination } from '@/lib/getPagination';
import { CompanyResponceContentI } from '@/services/api/company/type';
import { CompanyRowI } from './type';
import useSession from '../useSession';

const transformData = (companyContent: CompanyResponceContentI[]): CompanyRowI[] => {
  return companyContent.map(company => {
    return {
      id: company.id,
      companyName: company.name,
      platform: company.platform,
      createdAt: company.createdAt,
      reportNumber: company.reportNumber
    };
  });
};

const useGetCompany = (params: TGetPageParams) => {
  const { currentWorkspaceId } = useSession();

  const {
    data: body,
    isLoading,
    error,
    refetch
  } = useGetCompaniesQuery({ ...params, currentWorkspaceId });
  const data = body?.data;

  return {
    isLoading,
    companies: data?.content ? transformData(data.content) : [],
    pagination: getPagination(data),
    error,
    refetch
  };
};

export default useGetCompany;
