export const PARAMETER_UI_ELEMENTS = {
  SWITCH: 'SWITCH',
  DROPDOWN: 'DROPDOWN',
  CHECKBOX: 'CHECKBOX',
  INPUT: 'INPUT'
};

export const PARAMETER_DATA_TYPES = {
  STRING: 'STRING',
  BOOLEAN: 'BOOLEAN',
  INTEGER: 'INTEGER',
  DATE: 'DATE',
  DOUBLE: 'DOUBLE'
};

export enum PERIOD_TYPE_ENUM {
  DAY = 'days',
  WEEK = 'weeks',
  MONTH = 'months',
  YEAR = 'years',
}

export const PERIOD_TYPE_OPTIONS = [
  { name: 'Day(s)', value: 'days' },
  { name: 'Week(s)', value: 'weeks' },
  { name: 'Month(s)', value: 'months' },
  { name: 'Year(s)', value: 'years' },
];

export const DURATION_OPTIONS = new Array(30)
  .fill(0)
  .map((_, index) => ({
    name: `${index + 1}`,
    value: `${index + 1}`,
  }));

export enum START_FROM_ENUM {
  START_FROM = 'Start from',
  AGO_FROM_TODAY = 'Ago from Today',
}

export enum START_FROM_ENUM_NAME {
  START_FROM = '1',
  AGO_FROM_TODAY = '0'
}

export const START_FROM_OPTIONS = [
  { name: 'Start from', value: START_FROM_ENUM_NAME.START_FROM },
  { name: 'Ago from Today', value: START_FROM_ENUM_NAME.AGO_FROM_TODAY },
];

export const WEEKS_OPTIONS = [
  { name: 'Monday', value: 'Monday' },
  { name: 'Tuesday', value: 'Tuesday' },
  { name: 'Wednesday', value: 'Wednesday' },
  { name: 'Thursday', value: 'Thursday' },
  { name: 'Friday', value: 'Friday' },
  { name: 'Saturday', value: 'Saturday' },
  { name: 'Sunday', value: 'Sunday' },
];

export const MONTH_OPTIONS = [
  { name: 'January 1st', value: 'January 1st' },
  { name: 'February 1st', value: 'February 1st' },
  { name: 'March 1st', value: 'March 1st' },
  { name: 'April 1st', value: 'April 1st' },
  { name: 'May 1st', value: 'May 1st' },
  { name: 'June 1st', value: 'June 1st' },
  { name: 'July 1st', value: 'July 1st' },
  { name: 'August 1st', value: 'August 1st' },
  { name: 'September 1st', value: 'September 1st' },
  { name: 'October 1st', value: 'October 1st' },
  { name: 'November 1st', value: 'November 1st' },
  { name: 'December 1st', value: 'December 1st' }
];

export enum PARAMETER_VALUES_DATE_KEYS_NAME {
  PERIOD_TYPE = 'time_unit',
  DURATION = 'duration',
  START_FROM = 'custom_start_from',
  START_DAY = 'custom_start_day',
}

export const PARAMETER_VALUES_DATE_KEYS_NAME_ARRAY = [PARAMETER_VALUES_DATE_KEYS_NAME.PERIOD_TYPE, PARAMETER_VALUES_DATE_KEYS_NAME.DURATION, PARAMETER_VALUES_DATE_KEYS_NAME.START_FROM, PARAMETER_VALUES_DATE_KEYS_NAME.START_DAY]

export enum PARAMETER_VALUES_TYPES {
  INTEGER = 'INTEGER',
  STRING = 'STRING',
  BOOLEAN = 'BOOLEAN',
}

export const EMPTY_FIELD_IN_TEMPLATE_PARAMETER = {
  id: 0,
  uiElement: '123',
  required: true,
  valueOptions: []
};