import { FC, useMemo, useState } from 'react';
import { Box, SelectChangeEvent, Stack, Typography } from '@mui/material';
import {
  AccountButton,
  ContentWrapper,
  OutlinedLightButton,
  PrimaryButton,
  SearchInput
} from '@/components';
import {
  SelectReportingMethodBodyStyled,
  SelectReportingMethodFooterStyled,
  SelectReportingMethodStyled,
  AccountButtonsStyled
} from './selectReportingMethod.styled';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { StyleConfig } from '../report/type';
import CustomButtonGroup from './сustomButtonGroup';
import { DefaultIntervalValues, IntervalI, INTERVALS } from './type';
import useGetCompany from '@/hooks/companyService/useCompany';
import { SelectIntervalSection } from './selectInterval';
import { CompanyRowI } from '@/hooks/companyService/type';
import { MOCK_COMPANY } from './selectReportingMethodExcel';
import { useGoogleLogin } from '@react-oauth/google';
import { usePostGoogleConnectMutation } from '@/services/api/user';

const daysOfWeek = [
  {
    label: 'Monday',
    value: 0
  },
  {
    label: 'Tuesday',
    value: 1
  },
  {
    label: 'Wednesday',
    value: 2
  },
  {
    label: 'Thursday',
    value: 3
  },
  {
    label: 'Friday',
    value: 4
  },
  {
    label: 'Saturday',
    value: 5
  },
  {
    label: 'Sunday',
    value: 6
  }
];

export interface SelectReportMethodForm {
  daysOfMonth: number[];
  daysOfWeek: number[];
  companyIds: number[];
  hourOfDay: number;
  period: IntervalI;
  email?: string;
  file?: File | null;
  format?: 'EXCEL' | 'GOOGLE_SHEETS';
  filePath?: string;
}

export type TSelectReportingMethodProps = {
  userName: string;
  email: string;
  avatarUrl: string;
  onBack?: () => void;
  styleConfig?: StyleConfig;
  onApply?: (value: SelectReportMethodForm) => void; /// fix when add real data
  onHandlerClickGoogleAccount?: () => void;
  company?: CompanyRowI;
  defaultInterval?: DefaultIntervalValues;
};

interface UserData {
  email: string;
  name: string;
  avatarUrl: string;
}

const boxStyles = {
  display: 'grid',
  gridTemplateColumns: { xs: '1fr', lg: 'repeat(2, 1fr)' },
  gap: '2rem'
};

const radioStyles = {
  display: 'grid',
  gridTemplateColumns: { xs: '1fr', sm: 'repeat(3, 1fr)' },
  gap: '.5rem'
};

const MIN_HOUR = 1;
const MAX_HOUR = 23;

interface FormError {
  googleAccount?: boolean;
  folderPath?: boolean;
}

const SelectReportingMethod: FC<TSelectReportingMethodProps> = ({
  userName,
  email,
  avatarUrl,
  onBack,
  styleConfig,
  onApply,
  onHandlerClickGoogleAccount,
  company = MOCK_COMPANY,
  defaultInterval
}) => {
  const [isSelect, setIsSelect] = useState<boolean>(false);
  const { companies } = useGetCompany({
    page: 0,
    size: 100
  });
  const [inputMonth, setInputMonth] = useState('1');
  const [selectInterval, setSelectInterval] = useState<IntervalI>(
    defaultInterval?.selectInterval || INTERVALS.DAILY
  );
  const [hourOfDay, setHourOfDay] = useState<number>(defaultInterval?.hourOfDay || MIN_HOUR);
  const [dayOfWeek, setDayOFWeek] = useState<number[]>(defaultInterval?.dayOfWeek || []);
  const [dayOfMonth, setDayOfMonth] = useState<number[]>(defaultInterval?.dayOfMonth || []);
  const [selectCompanies, setSelectCompanies] = useState<number[]>([company.id]);
  const [postGoogleConnect] = usePostGoogleConnectMutation();
  const [renderUser, setRenderUser] = useState<UserData | undefined>(undefined);
  // {
  //   name: userName,
  //   email: email,
  //   avatarUrl: avatarUrl
  // }
  const [filePath, setFilePath] = useState('');
  const [formErrors, setFormErrors] = useState<FormError | undefined>({});

  const handleSetInputMonth = (e: React.ChangeEvent<HTMLInputElement>) =>
    setInputMonth(e.target.value);

  const boxStyleTemplate = useMemo(() => {
    if (!styleConfig?.isModalTemplate) {
      return boxStyles;
    }
    return {
      ...boxStyles,
      gridTemplateColumns: { xs: '1fr', sm: '1fr' }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [styleConfig?.isModalTemplate]);

  const customDownButtons = useMemo(() => {
    return styleConfig?.customDownButtons || [];
  }, [styleConfig?.customDownButtons]);

  const onHandlerApply = () => {
    if (!filePath || !renderUser) {
      setFormErrors({
        googleAccount: !renderUser,
        folderPath: !filePath
      });
      return;
    }

    const result = {
      period: selectInterval,
      hourOfDay: hourOfDay,
      daysOfWeek: dayOfWeek,
      daysOfMonth: dayOfMonth,
      companyIds: selectCompanies,
      email: renderUser.email,
      filePath: filePath
    };
    //// TODO: add check required
    onApply && onApply(result);
  };

  const handleChangeDayOfWeek = (event: SelectChangeEvent<typeof dayOfWeek>) => {
    const {
      target: { value }
    } = event;
    setDayOFWeek(typeof value !== 'string' ? value.map((item: string | number) => +item) : []);
  };

  const onHandlerChangeHourOfDay = (hour: string) => {
    if (+hour > MAX_HOUR || +hour < MIN_HOUR) {
      return;
    }
    setHourOfDay(+hour);
  };

  const handleChangeDayOfMonth = (event: SelectChangeEvent<typeof dayOfWeek>) => {
    const {
      target: { value }
    } = event;
    setDayOfMonth(typeof value !== 'string' ? value.map((item: string | number) => +item) : []);
  };

  const onHandlerChangeSelectCompanies = (event: SelectChangeEvent<typeof selectCompanies>) => {
    const {
      target: { value }
    } = event;
    setSelectCompanies(
      typeof value !== 'string' ? value.map((item: string | number) => +item) : []
    );
  };

  const resultForCustomButton = useMemo(() => {
    return {
      period: selectInterval,
      hourOfDay: hourOfDay,
      daysOfWeek: dayOfWeek,
      daysOfMonth: dayOfMonth,
      companyIds: selectCompanies,
      format: 'GOOGLE_SHEETS'
    };
  }, [selectInterval, hourOfDay, dayOfWeek, dayOfMonth, selectCompanies]);

  const onHandleGoogleSignIn = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: async ({ code }) => {
      console.log('code: ', code);
      const res = await postGoogleConnect(code);
      const { data }: any = res;
      if (data.data) {
        setRenderUser({
          name: data.data.name,
          email: data.data.email,
          avatarUrl: data.data.picture
        });
        setFormErrors(undefined);
      }
      console.log('data: ', res);
    },
    onError: err => {
      console.log('gError:', err);
    },
    scope: 'openid email profile  https://www.googleapis.com/auth/drive'
    // eslint-disable-next-line camelcase
    // redirect_uri: 'https://app.stage.clearsheets.io/google-switch-callback'
  });

  return (
    <Box sx={{ mt: styleConfig?.isNotMarginTop ? '0' : '2rem' }}>
      <ContentWrapper
        title={styleConfig?.isNotHeaderTitle ? undefined : 'Select reporting method'}
        isNotBorder={styleConfig?.isNotBorder}
      >
        <SelectReportingMethodStyled>
          <SelectReportingMethodBodyStyled>
            <Stack direction="column" gap="1.5rem">
              <Stack direction="column" gap="1rem">
                <Box sx={boxStyleTemplate}>
                  <Stack direction="column" gap="1rem">
                    <Typography>Select google account</Typography>
                    <AccountButtonsStyled
                      sx={{
                        gridTemplateColumns: { xs: '1fr', sm: 'repeat(2, 1fr)' },
                        height: '63px'
                      }}
                    >
                      {renderUser && (
                        <AccountButton
                          title={renderUser.name}
                          description={renderUser.email}
                          avatar={renderUser.avatarUrl}
                          isSelect={isSelect}
                          onClick={() => {
                            onHandlerClickGoogleAccount && onHandlerClickGoogleAccount();
                            setIsSelect(true);
                          }}
                        />
                      )}

                      <AccountButton
                        customClassNameAccountButton={{
                          borderColor: formErrors?.googleAccount ? 'red' : 'none'
                        }}
                        onClick={onHandleGoogleSignIn}
                        title="Use another account"
                      />
                    </AccountButtonsStyled>
                  </Stack>

                  <Stack
                    direction="column"
                    gap="34px"
                    sx={{ display: 'flex', justifyContent: 'flex-end' }}
                  >
                    <Stack direction="row" gap=".2rem">
                      <Typography
                        sx={{
                          fontSize: '14px',
                          fontWeight: '500'
                        }}
                      >
                        Select update interval
                      </Typography>
                      <Typography color="#AFAFAF">(folder + filename)</Typography>
                    </Stack>
                    <SearchInput
                      title=""
                      isNotShowSearchIcon
                      inputStyle={{
                        paddingBottom: '17px',
                        borderColor: formErrors?.folderPath ? 'red' : 'none'
                      }}
                      onChange={setFilePath}
                    />
                  </Stack>
                </Box>
              </Stack>

              <SelectIntervalSection
                selectInterval={selectInterval}
                setSelectInterval={setSelectInterval}
                hourOfDay={hourOfDay}
                setHourOfDay={setHourOfDay}
                dayOfWeek={dayOfWeek}
                setDayOFWeek={setDayOFWeek}
                selectCompanies={selectCompanies}
                setSelectCompanies={setSelectCompanies}
                dayOfMonth={dayOfMonth}
                setDayOfMonth={setDayOfMonth}
                titleSx={{
                  fontSize: '14px',
                  fontWeight: '500'
                }}
              />

              {/* {isSelect && (
                <Stack direction="column" gap="1rem">
                  <Box
                    sx={{
                      ...boxStyles,
                      gridTemplateColumns: { xs: '1fr', sm: 'repeat(6, 1fr)' }
                    }}
                  >
                    <SearchInput
                      title="Link to google sheets"
                      inputStyle={{ width: '100%' }}
                      isNotShowSearchIcon
                    />
                  </Box>
                </Stack>
              )} */}
            </Stack>
          </SelectReportingMethodBodyStyled>
          <SelectReportingMethodFooterStyled>
            {!customDownButtons.length && (
              <Stack direction="row" justifyContent="flex-end">
                <Stack direction="row" gap=".75rem" alignItems="center">
                  <OutlinedLightButton
                    label="Back"
                    startIcon={
                      <ArrowBackIcon
                        sx={{
                          color: '#747A82'
                        }}
                      />
                    }
                    onClick={onBack}
                  />
                  <PrimaryButton onClick={onHandlerApply} label="Apply" />
                </Stack>
              </Stack>
            )}
            <CustomButtonGroup
              customDownButtons={customDownButtons}
              value={resultForCustomButton}
            />
          </SelectReportingMethodFooterStyled>
        </SelectReportingMethodStyled>
      </ContentWrapper>
    </Box>
  );
};

export default SelectReportingMethod;
