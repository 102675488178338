import { quickbooksUserManager } from '@/services/oidcClient';
import { oidcConfig } from '@/services/oidcClient/config';
import {User, UserManager} from 'oidc-client-ts';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const ConnectToQuickBooksLauncher = () => {
  const navigate = useNavigate();

  useEffect(() => {
    quickbooksUserManager.signinRedirect();
    const userManager = new UserManager(oidcConfig);
    userManager.signinRedirectCallback().then((user: User) => {
      console.log('USER: ', user);
      navigate('/');
    });
  }, [navigate]);

  return <div>Connecting to QuickBooks...</div>;
};
