import { OnFinishData } from '@/components/report/type';
import { EFORMAT_API, ReportFormat } from '@/entity';
import { TReportBody } from '@/services/api/reports/types';
import { formatISO } from 'date-fns';
import { MOCK_CREATE_REPORT_BODY } from '@/utils/mockData/createReport';
import { usePostReportMutation } from '@/services';
import { formationSchedule } from '@/pages/CreateNewReportPage/helpers';
import { SelectReportMethodForm } from '@/components/selectReportingMethod/SelectReportingMethod';
import { ParameterValueApply } from '@/components/parameterValues/type';
import useSession from '../useSession';
import { ReportTemplateApiTab } from '@/services/api/reportTemplates/types';
import { logEventAnalytics } from '@/analytics';
import useHandleFormApiErrors from '../useHandleFormApiErrors';
import { usePutReportTemplateFileMutation } from '@/services/api/reportTemplate';
import { useNavigate } from 'react-router-dom';
import { PARAMETER_VALUES_TYPES } from '@/components/parameterValues/const';

export const useCreateReport = () => {
  const [onCreateReport] = usePostReportMutation();
  const { currentWorkspaceId } = useSession();
  const handleFormApiErrors = useHandleFormApiErrors();
  const [uploadReporttemplateFile] = usePutReportTemplateFileMutation();
  const navigate = useNavigate();

  const parametersByFormat: Record<
    ReportFormat,
    Pick<TReportBody, 'deliveryFormat' | 'reportFormat'>
  > = {
    [EFORMAT_API.GOOGLE_SHEETS]: {
      deliveryFormat: 'SCHEDULED_EMAIL',
      reportFormat: EFORMAT_API.GOOGLE_SHEETS
    },
    [EFORMAT_API.EXCEL]: {
      deliveryFormat: 'SCHEDULED_SPREADSHEET',
      reportFormat: EFORMAT_API.EXCEL
    }
  };

  const initSchedule = (
    reportingMethod: SelectReportMethodForm,
    parameterValue?: ParameterValueApply
  ) => {
    const schedule = formationSchedule(reportingMethod);
    const startDate = parameterValue?.startDate
      ? parameterValue?.startDate.toString()
      : MOCK_CREATE_REPORT_BODY.startDate;

    const endDate = parameterValue?.endDate
      ? parameterValue?.endDate.toString()
      : MOCK_CREATE_REPORT_BODY.endDate;

    return {
      schedule,
      startDate: formatISO(new Date(startDate)),
      endDate: formatISO(new Date(endDate))
    };
  };

  const convertTabsValuesForRequest = (parameterTabValues: ReportTemplateApiTab[] | undefined) => {
    return parameterTabValues
      ? parameterTabValues.map(tab => ({
        sheetIndex: tab.sheetIndex,
        reportParameters: tab.templateParametersValuesRender
          ? Object.values(tab.templateParametersValuesRender).map(keyField => ({
            name: keyField.name,
            value: String(keyField.value),
            dataType: keyField.dataType,
            key: keyField.key
          }))
          : []
      }))
      : [];
  };

  const getValueByDateType = (value: string | boolean | number | any, type: string) => {
    switch (type) {
      case 'number':
        return Number(value);
      case 'boolean':
        return !!value;
      default:
        return value as string;
    }
  }

  const onCreate = async (reqBody: OnFinishData, format: ReportFormat) => {
    const { reportingMethod, parameterValue, template, parameterTabValues } = reqBody;

    if (!reportingMethod) {
      return undefined;
    }

    const { schedule, startDate, endDate } = initSchedule(reportingMethod, parameterValue);

    const prepareBody: TReportBody = {
      ...parametersByFormat[format],
      companyIds: reportingMethod.companyIds,
      emails: [reportingMethod.email || 'test@gmail.com'],
      schedule,
      startDate,
      endDate,
      reportTemplateId: template?.id || MOCK_CREATE_REPORT_BODY.reportTemplateId,
      name: parameterValue?.reportName || MOCK_CREATE_REPORT_BODY.name,
      reportParameters:
        parameterValue?.parameters?.map(({ name, value, dataType, key }) => ({
          name,
          value: getValueByDateType(value, dataType),
          dataType,
          key
        })) || MOCK_CREATE_REPORT_BODY.reportParameters,
      reportTabs: convertTabsValuesForRequest(parameterTabValues),
      googleSheetsInfo: {
        email: reportingMethod.email || '',
        // eslint-disable-next-line camelcase
        file_path: reportingMethod.filePath || ''
      }
    };

    const response: any = await onCreateReport({ ...prepareBody, workspaceId: currentWorkspaceId });
    return response?.data?.data ? response?.data?.data : { isError: true, message: response?.error?.data?.message || 'An unforeseen error occurrederror' };
  };

  const onApply = async (reqBody: OnFinishData, isGoogleSheet: ReportFormat) => {
    logEventAnalytics('Click', 'Create Report');

    const response = await onCreate(reqBody, isGoogleSheet);
    if (response?.isError) {
      handleFormApiErrors({
        error: {},
        defaultMessage: response?.message ?? 'Something went wrong, please try again.',
        showFieldErrorsAsToast: true
      });
      // console.error('Error: reportingMethod is empty');
      return false;
    }

    const file = reqBody?.reportingMethod?.file;
    if (file) {
      const reportTemplateId = response?.reportTemplate?.id;
      await uploadReporttemplateFile({ id: reportTemplateId, file: file });
    }

    return true;
  }

  return { onCreate, convertTabsValuesForRequest, onApply };
};
