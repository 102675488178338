import React, { FC } from 'react';
import {
  AccountButtonAvatarStyled,
  AccountButtonContentStyled,
  AccountButtonStyled
} from './accountButton.styled';
import { ButtonProps, SxProps, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { UserCircleIcon } from '@/components/icons';
import { Theme } from '@/styles/theme/types';

export type TAccountButtonProps = {
  avatar?: string;
  title: string;
  description?: string;
  isSelect?: boolean;
  onClick?: () => void;
  customClassNameAccountButton?: SxProps<any> | undefined;
} & ButtonProps;

const AccountButton: FC<TAccountButtonProps> = ({
  avatar,
  title,
  description,
  isSelect,
  onClick,
  customClassNameAccountButton
}) => {
  return (
    <AccountButtonStyled
      fullWidth
      sx={{
        border: isSelect ? '1px solid #1793E5' : '1px solid #DFE1E3',
        ...(customClassNameAccountButton ? customClassNameAccountButton : {})
      }}
      onClick={onClick}
    >
      <AccountButtonAvatarStyled>
        {avatar && <Avatar src={avatar} sx={{ width: 28, height: 28 }} alt={title || 'pict'} />}
        {!avatar && (
          <Avatar src={avatar} sx={{ width: 28, height: 28, bgcolor: 'transparent' }} alt="pict">
            <UserCircleIcon fill="#C0C0C1" />
          </Avatar>
        )}
      </AccountButtonAvatarStyled>
      <AccountButtonContentStyled>
        <Typography variant="body2">{title}</Typography>
        {description && <Typography variant="caption">{description}</Typography>}
      </AccountButtonContentStyled>
    </AccountButtonStyled>
  );
};

export default AccountButton;
